var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth",attrs:{"id":"Auth"}},[_c('div',{staticClass:"auth__container"},[_c('div',{staticClass:"auth_span",staticStyle:{"width":"100%","margin-bottom":"10px"}},[_c('img',{staticClass:"img_logo",staticStyle:{"max-width":"50%"},attrs:{"src":'//api.mysimulator.ru' + _vm.simInfo.logo,"alt":""}})]),_c('div',{staticClass:"auth__head"},[_c('div',{staticClass:"auth__head__type"},[_vm._v(" "+_vm._s(_vm.type === 1 ? "Войти" : _vm.type === 2 ? "Зарегистрироваться" : "Восстановить пароль")+" ")])]),_c('form',{staticClass:"auth__body",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('div',[(_vm.type < 3)?_c('FormInput',{attrs:{"valid":_vm.validEmail,"error":_vm.errorMailMessage,"title":"E-mail","placeholder":"Введите e-mail"},model:{value:(_vm.formdata.email),callback:function ($$v) {_vm.$set(_vm.formdata, "email", $$v)},expression:"formdata.email"}}):_vm._e(),(_vm.type < 3 || _vm.type === 4)?_c('FormInput',{attrs:{"valid":_vm.validPass,"error":_vm.errorPassMessage,"type":"password","title":"Пароль","placeholder":"Введите пароль"},model:{value:(_vm.formdata.password),callback:function ($$v) {_vm.$set(_vm.formdata, "password", $$v)},expression:"formdata.password"}}):_vm._e(),(_vm.type === 2 || _vm.type === 4)?_c('FormInput',{attrs:{"valid":_vm.validRePass,"error":_vm.errorRePassMessage,"type":"password","title":"Пароль","placeholder":"Повторите пароль"},model:{value:(_vm.formdata.rePassword),callback:function ($$v) {_vm.$set(_vm.formdata, "rePassword", $$v)},expression:"formdata.rePassword"}}):_vm._e(),(_vm.type === 3)?_c('FormInput',{attrs:{"placeholder":"Введите почту","title":"Email"},model:{value:(_vm.formdata.email),callback:function ($$v) {_vm.$set(_vm.formdata, "email", $$v)},expression:"formdata.email"}}):_vm._e(),(
            _vm.type === 2 &&
            _vm.simInfo &&
            _vm.simInfo.agreement_url &&
            _vm.simInfo.data_processing_url &&
            _vm.simInfo.agreement_url != '' &&
            _vm.simInfo.data_processing_url != ''
          )?_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"id":"checkbox-1","name":"checkbox-1","value":"accepted"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Я принимаю "),_c('a',{attrs:{"href":_vm.simInfo.agreement_url,"target":"_blank"}},[_vm._v("правила пользования сервисом")]),_vm._v(" и "),_c('a',{attrs:{"href":_vm.simInfo.data_processing_url,"target":"_blank"}},[_vm._v(" политику обработки персональных данных")])]):_vm._e()],1),_c('div',[_c('FormButton',{staticStyle:{"margin-bottom":"10px"},attrs:{"inactive":_vm.isaccept,"loading":_vm.formLoading,"text":_vm.type === 1
              ? 'Войти'
              : _vm.type === 2
              ? 'Зарегистрироваться'
              : _vm.type === 3
              ? 'Сменить пароль'
              : 'Изменить пароль',"size":"full-width","type":"submit"}}),_c('div',{staticClass:"auth__change"},[(_vm.type === 1)?_c('div',{on:{"click":function($event){_vm.type = 2}}},[_vm._v("Зарегистрироваться")]):_vm._e(),(_vm.type > 1)?_c('div',{on:{"click":function($event){_vm.type = 1}}},[_vm._v("Войти")]):_vm._e(),(_vm.type === 1)?_c('div',{on:{"click":function($event){_vm.type = 3}}},[_vm._v("Забыли пароль?")]):_vm._e()]),(_vm.type < 3 && _vm.simInfo)?_c('div',{staticClass:"mt-2"},[(
              !!_vm.simInfo.group_info.auth_facebook_key || !!_vm.simInfo.group_info.auth_vk_key
            )?_c('hr'):_vm._e(),(!!_vm.simInfo.group_info.auth_facebook_key)?_c('Facebook',{staticClass:"mr-2",staticStyle:{"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.socialInit('facebook')}}}):_vm._e(),(!!_vm.simInfo.group_info.auth_vk_key)?_c('VK',{staticStyle:{"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.socialInit('vk')}}}):_vm._e()],1):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }